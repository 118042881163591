import { createAsyncThunk } from '@reduxjs/toolkit'
import { newsLetterClubListApi, newsLetterDocumentListApi } from './NewsLetter.services'
import { paginationData } from '../Pagination/reducers'
import { paginationDocData } from './reducer'
import { toast } from 'react-toastify'

//services
export const NewsLetterClubList = createAsyncThunk(
    '@NewsLetter/LIST',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await newsLetterClubListApi(data)

            if (data.params._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const NewsLetterDocumentList = createAsyncThunk(
    '@NewsLetter/Document/LIST',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await newsLetterDocumentListApi(data)

            if (data.params._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationDocData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            if (err.response.data) {
                toast.error(err.response.data)
            }
            return rejectWithValue(err.response.data)
        }
    }
)