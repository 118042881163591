import { createSlice } from '@reduxjs/toolkit'
import { PlanListByMember, PurchasedMemberShipList, SubMemberMemberList } from './thunk'
import _ from 'lodash'
import moment from 'moment'

export const initialState = {
    list: [],
    loading: true,
    subMemberMembershipData: {
        list: [],
        loading: true
    },
    planListByMember: {
        list: [],
        loading: true
    }
}
const PurchasedMembership = createSlice({
    name: 'PurchasedMemberShipList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(PurchasedMemberShipList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(PurchasedMemberShipList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            state.loading = false
        })
        builder.addCase(PurchasedMemberShipList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SubMemberMemberList.pending, (state) => {
            state.subMemberMembershipData.list = []
            state.subMemberMembershipData.loading = true
        })
        builder.addCase(SubMemberMemberList.fulfilled, (state, action) => {
            const { payload } = action
            state.subMemberMembershipData.list = payload || []
            state.subMemberMembershipData.loading = false
        })
        builder.addCase(SubMemberMemberList.rejected, (state) => {
            state.subMemberMembershipData.list = []
            state.subMemberMembershipData.loading = false
        })
        builder.addCase(PlanListByMember.pending, (state) => {
            state.planListByMember.list = []
            state.planListByMember.loading = true
        })
        builder.addCase(PlanListByMember.fulfilled, (state, action) => {
            const { payload } = action

            state.planListByMember.list = _.map(payload || [], (o) => {
                return {
                    ...o,
                    isPlanExpired: o?.expires_on
                        ? moment(moment(o?.expires_on).format('YYYY-MM-DD')).isBefore(
                              moment().format('YYYY-MM-DD')
                          )
                        : false
                }
            })
            state.planListByMember.loading = false
        })
        builder.addCase(PlanListByMember.rejected, (state) => {
            state.planListByMember.list = []
            state.planListByMember.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default PurchasedMembership.reducer
