import { createSlice } from '@reduxjs/toolkit'
import { ClubIDConfiguration, ClubPaymentIntent } from './thunk'
import _ from 'lodash'

export const initialState = {
    shoppingCart: {},
    paymentData: {},
    clubIdConfiguration: {},
    loading: false
}
const ClubShoppingCart = createSlice({
    name: 'clubShoppingCart',
    initialState,
    reducers: {
        addToShoppingCart: (state, action) => {
            const { payload } = action
            state.shoppingCart = payload
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubPaymentIntent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubPaymentIntent.fulfilled, (state, action) => {
            state.paymentData = action.payload
            state.loading = false
        })
        builder.addCase(ClubPaymentIntent.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubIDConfiguration.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubIDConfiguration.fulfilled, (state, action) => {
            state.clubIdConfiguration = _.first(action?.payload?.data)
            state.loading = false
        })
        builder.addCase(ClubIDConfiguration.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { addToShoppingCart } = ClubShoppingCart.actions
export default ClubShoppingCart.reducer
