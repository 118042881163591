import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const PurchasedMembershipListApi = (data, params) =>
    API.post(`${URL.CLUB_PURCHASED_MEMBERSHIP_LIST}`, data, {
        params
    })

export const PlanListByMemberApi = (data) => {
    return API.post(`${URL.PLAN_LIST_BY_MEMBER}`, data.bodyData, {
        params: data?.params
    })
}

export const SubMemberMemberShipListApi = (data) => {
    return API.post(`${URL.SUB_MEMBER_MEMBERSHIP_LIST}`, data?.bodyData)
}
