import { createSlice } from '@reduxjs/toolkit'
import { NewsLetterClubList, NewsLetterDocumentList } from './thunk'

export const initialState = {
    list: [],
    newsLetterDocumentList: {
        page: 1,
        limit: process.env.REACT_APP_PAGE_LIMIT || 10,
        totalPage: 1,
        totalRecords: 1,
        list: [],
        loading: false
    },
    activeClub: '',
    loading: true
}
const NewsLetter = createSlice({
    name: '@NewsLetter',
    initialState,
    reducers: {
        changeActiveNewsletter: (state, action) => {
            state.activeClub =
                state.activeClub === action.payload.activeClub ? '' : action.payload.activeClub
        },
        paginationDocData: (state, action) => {
            const { payload } = action
            state.newsLetterDocumentList.totalPage = payload?.totalPage || 1
            state.newsLetterDocumentList.totalRecords = payload?.totalRecords || 1
            state.newsLetterDocumentList.loading = false
        },
        nextPrevPageDoc: (state, action) => {
            const { payload } = action
            state.newsLetterDocumentList.page = payload || 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(NewsLetterClubList.pending, (state) => {
            state.activeClub = ''
            state.loading = true
        })
        builder.addCase(NewsLetterClubList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            state.loading = false
        })
        builder.addCase(NewsLetterClubList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(NewsLetterDocumentList.pending, (state) => {
            state.newsLetterDocumentList.loading = true
        })
        builder.addCase(NewsLetterDocumentList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.newsLetterDocumentList.list = data
            state.newsLetterDocumentList.loading = false
        })
        builder.addCase(NewsLetterDocumentList.rejected, (state) => {
            state.newsLetterDocumentList.list = []
            state.newsLetterDocumentList.loading = false
        })
    }
})

export const { changeActiveNewsletter, nextPrevPageDoc, paginationDocData } = NewsLetter.actions
export default NewsLetter.reducer