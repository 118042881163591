import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    PlanListByMemberApi,
    PurchasedMembershipListApi,
    SubMemberMemberShipListApi
} from './PurchasedMembership.services'
import { paginationData } from '../Pagination/reducers'
import { toast } from 'react-toastify'
import _ from 'lodash'

//services
export const PurchasedMemberShipList = createAsyncThunk(
    'Clubs/PurchasedMembershipList',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await PurchasedMembershipListApi(data.body, data.params)

            if (data.params._limit) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const PlanListByMember = createAsyncThunk(
    'Plan/list/by/member',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await PlanListByMemberApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res.data
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SubMemberMemberList = createAsyncThunk(
    'Membership/SubMember/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubMemberMemberShipListApi(data)

            const finalRes = _.filter(res.data, (item) => item.user_data.user_role === 'subuser')

            return finalRes
        } catch (err) {
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
